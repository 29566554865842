import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "hidden" ]
  static values = {
    delaySec: Number
  };

  connect() {
    const self = this

    this.timer = setTimeout(() => {
      self.element.classList.remove(self.hiddenClass)
    }, this.delaySecValue * 1_000)
  }

  disconnect() {
    if (this.countdown) {
      cancelTimeout(this.timer)
    }
  }
}
